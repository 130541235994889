import wx from 'weixin-js-sdk'
import Compressor from 'compressorjs'
import { createSignature } from '@/api/weixin'
/**
 * 判断是否在微信环境
 * @returns 
 */
export const isWexin = () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('micromessenger') !== -1
}
/**
 * 判断是否在支付宝环境
 * @returns 
 */
export const isAlipay = () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('alipayclient') !== -1
}
/**
 * 根据参数名字截取url参数值
 * @returns 
 */
export function getQueryStringApp (name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
    if (window.location.href.indexOf("?") < 0) return null
    let r = window.location.href.split("?")[1].match(reg)
    if (r != null) return decodeURIComponent(r[2])
    return null
}
/**
 * 获取微信权限
 * @returns 
 */
export function wxConfig(params, jsApiArr = []) {
    createSignature(params).then((res) => {
        if (res) {
            wx.config({
                debug: false,
                appId: res.appId, // 必填，公众号的唯一标识
                timestamp: res.timeStamp, // 必填，生成签名的时间戳
                nonceStr: res.nonceStr, // 必填，生成签名的随机串
                signature: res.signature,// 必填，签名
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'].concat(jsApiArr) // 必填，需要使用的JS接口列表
            })
        }
    })
}
/**
 * base64转为可post的二进制数据
 * @returns 
 */
export function base64ToBlob(dataurl) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
/**
 * 防抖 延迟执行
 * @returns 
 */
export function _debounce(fn, delay = 500, immediate=false) {
    var timer = null
    return function () {
        var _this = this
        var args = arguments
        if (timer) clearTimeout(timer)
        if (immediate){
            var callNow = !timer
            timer = setTimeout(function () {
                timer = null
            }, delay)
            if (callNow) fn.apply(_this, args)
        } else {
            timer = setTimeout(function () {
                fn.apply(_this, args)
            }, delay)
        }
    }
}
/**
 * ios 返回重新刷新页面
 */
export function IOSReload() {
    let agent = navigator.userAgent.toLowerCase() //检测是否是ios
    if (agent.indexOf('iphone') >= 0 || agent.indexOf('ipad') >= 0) {
        var isPageHide = false
        window.addEventListener("pageshow", function() {
            if (isPageHide) {
                window.location.reload()
            }
        });
        window.addEventListener("pagehide", function() {
            isPageHide = true
        })
    }
}
/**
 * 强制保留2位小数
 */
export function toDecimal2 (x) {
    x = x === 'error' ? 0 : x
    let f = parseFloat(x)
    if (isNaN(f)) return false
    f = Math.round(x * 100) / 100
    let s = f.toString()
    let rs = s.indexOf('.')
    if (rs < 0) {
        rs = s.length
        s += '.'
    }
    while (s.length <= rs + 2) {
        s += '0'
    }
    return s
}
/**
 * 压缩图片
 */
export async function compressImage(file, quality) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: quality || 0.6,
            success(result) {
                resolve(result)
            },
            error(err) {
                reject(err)
            }
        })
    })
}
/**
 * 减法
 */   
export function floatSub (arg1, arg2 ) {    
    var r1,r2,m,n
    try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
    try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
    m=Math.pow(10,Math.max(r1,r2))
    //动态控制精度长度
    n=(r1>=r2)?r1:r2
    return Number(((arg1*m-arg2*m)/m).toFixed(n))
}
// 设置年月日 date类型转换年月日
export function formatDate(date) {
    let month = date.getMonth() + 1
    if (month >= 1 && month <= 9) month = '0' + month
    let day = date.getDate()
    if (day >= 1 && day <= 9) day = '0' + day
    return [`${date.getFullYear()}`, `${month}`, `${day}`]
}
/**
 * 以当天为基础 获取某天之后7天的日期与星期
 * @param {*} dayIndex -1昨天 0 当天 1 明天
 */
export function getDayAndWeek (dayIndex=0) {
    const now = new Date()
    //从当前时间往后7天
    let checkData = []
    for (let i = dayIndex; i < (dayIndex+7); i++) {
        //24 * 3600 * 1000 就是计算一天的时间 
        const date = new Date(now.getTime() + i * 24 * 3600 * 1000)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        if (month >= 1 && month <= 9) month = '0' + month
        let day = date.getDate()
        if (day >= 1 && day <= 9) day = '0' + day
        const dt2 = new Date(now.getTime() + i * 24 * 3600 * 1000)
        const weekDay = ["日", "一", "二", "三", "四", "五", "六"]
        const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
        //把七天的时间和星期添加到数组中
        checkData.push({
            date: year + "-" + month + "-" + day,
            day: day,
            weekday: weekDays[dt2.getDay()],
            week: weekDay[dt2.getDay()],
        })
    }
    return checkData
}